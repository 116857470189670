import { useLayoutEffect, useState } from "react";

const useIsFirstRender = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useLayoutEffect(() => {
    setIsFirstRender(false);
  }, []);

  return isFirstRender;
};

export default useIsFirstRender;
